.label {
  @include border-radius(3px);
  @include font-size(10px);
  position: absolute;
  top: 12px;
  left: 12px;
  display: inline-block;
  padding: 2px 8px;
  color: $color-primary;
  text-align: center;
}

.label--primary {
  background-color: $color-secondary;
}

.label--secondary {
  background-color: $chateau-green;
  color: $white;
}

.label--tertiary {
  background-color: $flamingo;
  color: $white;
}
