.t-small {
  @include font-size(12px);
}

.t-uppercase {
  text-transform: uppercase;
}

.t-center {
  text-align: center;
}

.t-underline {
  text-decoration: underline;
}

.c-primary {
  color: $color-primary;
}

.c-secondary {
  color: $color-secondary;
}

.c-white {
  color: $white;
}

.c-boulder {
  color: $boulder;
}

.c-dusty-gray {
  color: $dusty-gray;
}

.c-dove-gray {
  color: $dove-gray;
}

.c-gray {
  color: $gray;
}

.c-silver-charlice {
  color: $silver-chalice;
}

.c-twitter {
  color: #55acee;
}

.c-facebook {
  color: #3b5998;
}

.c-pinterest {
  color: #cb2027;
}

.c-google-plus {
  color: #dd4b39;
}

.c-linkedin {
  color: #007bb5;
}

.c-instagram {
  color: #e95950;
}

.c-youtube {
  color: #ff0000;
}

.bg-primary {
  background-color: $color-primary;
}

.bg-secondary {
  background-color: $color-secondary;
}

.bg-white {
  background-color: $white;
}

.bg-mirage {
  background-color: rgba(19, 28, 40, 0.7);
}

.bg-wild-sand {
  background-color: $wild-sand;
}

.hover-effect {
  transition: 0.3s box-shadow ease-out;

  &:hover {
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.05);
  }
}

.map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
}

.no-b-margin {
  margin-bottom: 0;
}

.d-xxl-flex {
  @include MQ(XXL) {
    display: flex;
  }
}
