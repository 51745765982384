.pricing-table {
  @include border-radius(3px);
  position: relative;
  margin-top: 30px;
  padding: 30px;
  border: 1px solid $gallery;
  overflow: hidden;

  @include MQ(L) {
    padding: 30px 50px 50px;
  }
}

.pricing-table--featured {
  box-shadow: 0px 5px 11.64px 0.36px rgba(0, 0, 0, 0.13);
}

.pricing-table__header {
  margin-bottom: 45px;
  padding-bottom: 20px;
  border-bottom: 1px solid $gallery;
}

.pricing-table__name {
  @include font-size(22px);
  margin-bottom: 15px;
}

.pricing-table__price {
  @include font-size(40px);
  margin-bottom: 0;

  sup {
    font-size: 60%;
  }
}

.pricing-table__body {
  padding-bottom: 30px;
}

.pricing-table__feature {
  position: relative;
  margin-bottom: 15px;
  padding-left: 30px;

  &:before {
    content: '\f00c';
    position: absolute;
    display: inline-block;
    left: 0;
    color: $color-secondary;
    font-family: 'FontAwesome';
    font-weight: 400;
  }
}

.pricing-table__feature--not-supported {
  color: $gray;

  &:before {
    content: '\f00d';
    color: $gray;
  }
}
