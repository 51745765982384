// rem fallback - credits: http://zerosixthree.se/

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// border radius

@mixin border-radius($radius: 0.25em) {
  border-radius: $radius;
}

// antialiasing mode font rendering

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// media queries

@mixin MQ($breakpoint) {
  @if $breakpoint == S {
    @media screen and (min-width: $sm) {
      @content;
    }
  } @else if $breakpoint == M {
    @media screen and (min-width: $md) {
      @content;
    }
  } @else if $breakpoint == L {
    @media screen and (min-width: $lg) {
      @content;
    }
  } @else if $breakpoint == XL {
    @media screen and (min-width: $xl) {
      @content;
    }
  } @else if $breakpoint == XXL {
    @media screen and (min-width: $xxl) {
      @content;
    }
  }
}
