/*
 * Completely resets form items
 * ----------------------------
 * Super hard reset that removes all borders
 *   and radiuses of all form items (including
 *   checkboxes and radios)
 */

input,
textarea,
button {
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: left;
}

input[type='checkbox'] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

textarea {
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 10px;
  @include font-size(14px);
}

fieldset {
  margin-bottom: 1rem;
}

.form-container {
  @include border-radius(3px);
  padding: 40px 30px;
  box-shadow: 0px 5px 11.64px 0.36px rgba(0, 0, 0, 0.13);
}

.form-title {
  @include font-size(28px);
  margin-bottom: 25px;
}

.form-social {
  padding-bottom: 10px;
  border-bottom: 1px solid $gallery;
  margin-bottom: 25px;
}

.form-input {
  display: block;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid transparent;
  background: $white;
  color: $color-primary;
  @include border-radius(3px);
  box-shadow: none;
  transition: all 0.3s ease-out;
}

.form-input--large {
  padding-top: 12px;
  padding-bottom: 12px;
}

.form-input--pill {
  @include border-radius(20px);
}

.form-input--border-c-gallery {
  border-color: $gallery;
}

.form-input--border-c-alto {
  border-color: $alto;
}

.form-input--social {
  padding-right: 65px;
}

.form-input--no-br {
  border-radius: 0;
}

.form-group {
  margin-bottom: 15px;
}

.form-group__title {
  @include font-size(16px);
  margin-bottom: 15px;

  @include MQ(M) {
    margin-top: 15px;
  }
}

.form-group__wrapper {
  position: relative;
}

.form-group__action {
  position: absolute;
  top: 50%;
  right: 5px;
  display: inline-block;
  transform: translateY(-50%);
}

.form-group__icon {
  @include font-size(16px);
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  height: 100%;
  width: 50px;
  border-left: 1px solid $alto;
  color: $dusty-gray;
  line-height: 45px;
  text-align: center;
  transform: translateY(-50%);

  &--social {
    border-left-color: rgba(255, 255, 255, 0.15);
  }
}

.form-group--submit {
  padding-top: 10px;
}

.form-group--footer {
  padding-top: 20px;
}

.form-group__container {
  display: flex;
  justify-content: space-between;
}

textarea {
  overflow: auto;
}

.button {
  display: inline-block;
  border: 1px solid transparent;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.button--primary {
  background-color: $color-secondary;
  color: $color-primary;
}

.button--secondary {
  background-color: $color-primary;
  color: $white;
}

.button--small {
  padding: 8px 28px;
}

.button--medium {
  padding: 10px 40px;
}

.button--large {
  padding: 12px 48px;
}

.button--square {
  @include border-radius(3px);
}

.button--pill {
  @include border-radius(20px);
}

.button--block {
  display: block;
  width: 100%;
}

.button--facebook {
  background-color: #3b5998;
  color: $white;
}

.button--twitter {
  background-color: #55acee;
  color: $white;
}

.button--like {
  @include border-radius(50%);
  width: 28px;
  height: 28px;
  background-color: $alto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button--listing-action {
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid $gallery;

  @include MQ(L) {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.search-form {
  display: none;

  @media screen and (min-width: 1400px) {
    display: flex;
    align-items: center;
  }

  .form-group__wrapper:first-of-type {
    border-right: 1px solid $gallery;
  }

  .form-group__wrapper:first-of-type > * {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .form-group__wrapper:last-of-type > * {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.action {
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.edit {
  &:hover {
    color: $color-secondary;
  }
}

.remove {
  &:hover {
    color: $flamingo;
  }
}

.icheck_label {
  display: flex;
  align-items: center;

  .icheckbox_flat-yellow,
  .iradio_flat-yellow {
    margin-right: 5px;
  }
}

.form-login__block {
  display: none;
}

.form-login__block.is-selected {
  display: block;
}
