.single-listing-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 300px;

  @include MQ(M) {
    height: 450px;
  }
}

.single-listing-header-1 {
  background-image: url('../images/uploads/single-listing-bg-1.jpg');

  @include MQ(L) {
    height: 600px;
  }
}

.single-listing-header-2 {
  background-image: url('../images/uploads/single-listing-bg-2.jpg');

  @include MQ(L) {
    height: 660px;
  }
}

.single-listing-slider {
  max-width: 1920px;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 20px;
}

.single-listing--layout-2 {
  .listing-widget-container {
    @include MQ(L) {
      margin-top: -400px;
      z-index: 20;
    }
  }
}

.single-listing--layout-3 {
  .listing-widget-container {
    @include MQ(L) {
      margin-top: -250px;
      z-index: 20;
    }
  }
}

.single-listing--layout-4 {
  .listing-widget-container {
    @include MQ(L) {
      margin-top: -100px;
    }
  }

  .listing-section-container {
    @include MQ(L) {
      margin-top: -100px;
    }
  }
}
