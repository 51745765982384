// Breakpoints

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1920px;

// Colors

$mine-shaft: #282828;
$wild-sand: #f6f6f6;
$dove-gray: #666666;
$boulder: #777777;
$gray: #888888;
$dusty-gray: #999999;
$gallery: #eeeeee;
$white: #ffffff;
$flamingo: #f15636;
$chateau-green: #39b54a;
$silver-chalice: #aaaaaa;
$alto: #dddddd;
$mercury: #e5e5e5;
$picton-blue: #55acee;
$purple: #5e03ab;
$tundora: #444444;

$color-primary: #222222;
$color-secondary: #fcc400;

// Fonts
$font-primary: 'Cabin', sans-serif;
