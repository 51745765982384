html {
  @include font-smoothing;
}

body,
button,
input,
select,
textarea {
  color: $color-primary;
  font-family: $font-primary;
  @include font-size(14px);
  font-weight: 400;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px;
  font-weight: 600;
  line-height: 1.2;
}

p {
  margin: 0 0 25px;
}

h1 {
  @include font-size(44px);
  @include MQ(M) {
    @include font-size(60px);
  }
}

h2 {
  @include font-size(38px);
  @include MQ(M) {
    @include font-size(50px);
  }
}

h3 {
  @include font-size(28px);
  @include MQ(M) {
    @include font-size(40px);
  }
}

h4 {
  @include font-size(24px);
  @include MQ(M) {
    @include font-size(30px);
  }
}

h5 {
  @include font-size(20px);
}

h6 {
  @include font-size(16px);
}
