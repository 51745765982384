.ads {
  background-image: url('../images/uploads/ads-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.ads {
  .ads__container {
    padding: 100px 0;
  }
}

.ads--layout-2 {
  .ads__container {
    padding: 75px 0;
  }
}

.ads__title {
  @include font-size(36px);
  margin-bottom: 10px;
}

.ads__subtitle {
  @include font-size(16px);
  margin-bottom: 0;
}
