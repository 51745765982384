.page-section {
  padding-top: 50px;
  padding-bottom: 50px;

  @include MQ(M) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.page-section__title {
  @include font-size(28px);
  margin-bottom: 10px;
}

.page-section__subtitle {
  margin-bottom: 10px;
}
