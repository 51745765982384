/* iCheck plugin Flat skin, yellow
----------------------------------- */
.icheckbox_flat-yellow,
.iradio_flat-yellow {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  background: url('../images/icheck/yellow.png') no-repeat;
  border: none;
  cursor: pointer;
}

.icheckbox_flat-yellow {
  background-position: 0 0;
}
.icheckbox_flat-yellow.checked {
  background-position: -22px 0;
}
.icheckbox_flat-yellow.disabled {
  background-position: -44px 0;
  cursor: default;
}
.icheckbox_flat-yellow.checked.disabled {
  background-position: -66px 0;
}

.iradio_flat-yellow {
  background-position: -88px 0;
}
.iradio_flat-yellow.checked {
  background-position: -110px 0;
}
.iradio_flat-yellow.disabled {
  background-position: -132px 0;
  cursor: default;
}
.iradio_flat-yellow.checked.disabled {
  background-position: -154px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi),
  (min-resolution: 1.25dppx) {
  .icheckbox_flat-yellow,
  .iradio_flat-yellow {
    background-image: url('../images/icheck/yellow@2x.png');
    -webkit-background-size: 176px 22px;
    background-size: 176px 22px;
  }
}
