.category {
  @include border-radius(3px);
  margin-top: 30px;
  border: 1px solid $gallery;
  background-color: $white;
}

.category > a {
  display: block;
  padding-top: 26px;
  padding-bottom: 26px;
}

.category__title {
  @include font-size(15px);
  margin-bottom: 0;
}

.category--layout-1 {
  img {
    max-height: 50px;
  }

  .category__title {
    margin-top: 20px;
  }
}

.category--layout-2 {
  .category {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .category > a {
    padding-top: 45px;
    padding-bottom: 45px;

    @include MQ(M) {
      padding-top: 75px;
      padding-bottom: 75px;
    }

    &:hover .category__image {
      background-color: $color-secondary;
      border: 1px solid transparent;
      box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.2);
    }
  }

  .category__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin: 0 auto 20px;
    border: 1px solid $white;
    border-radius: 50%;
    line-height: 80px;
    transition: 0.3s all ease-out;
  }
}

.category--layout-3 {
  .category > a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .category__image {
    flex-basis: 20px;
    max-height: 20px;
    margin-right: 10px;
  }
}
