.main-navigation {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  padding: 0 10px 20px;
  border-top: 3px solid $color-secondary;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);

  @include MQ(L) {
    position: static;
    display: block !important;
    width: auto;
    padding: 0;
    border-top: 0;
    background-color: transparent;
    box-shadow: none;

    > li {
      display: inline-block;
    }

    > li > a {
      line-height: 60px;
      border-bottom: 0;
    }
  }

  li {
    position: relative;
  }

  li ul {
    position: absolute;
    left: 9999px;
    display: none;
    padding-left: 10px;

    @include MQ(L) {
      width: 13em;
      padding-left: 0;
      border-top: 3px solid $color-secondary;
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    }
  }

  > li.is-active > ul {
    position: static;
    display: block;

    @include MQ(L) {
      position: absolute;
      left: 0;
      top: auto;
      z-index: 20;
    }
  }

  li li.is-active ul {
    position: static;
    display: block;

    @include MQ(L) {
      position: absolute;
      left: 100%;
      top: 0;
      z-index: 20;
    }
  }

  a {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid $gallery;
    outline: none;

    @include MQ(L) {
      padding: 10px 15px;
    }
  }

  a:not(:only-child) {
    position: relative;

    &:after {
      content: '\f107';
      position: absolute;
      right: 2px;
      display: inline-block;
      font-family: 'FontAwesome';
    }

    @include MQ(L) {
      &:after {
        display: none;
      }
    }
  }

  a.is-active:not(:only-child):after {
    content: '\f106';
  }
}

.main-navigation--white {
  @include MQ(L) {
    > li > a {
      color: $white;

      &:hover {
        color: $color-secondary;
      }
    }
  }
}

.nav-mobile {
  position: absolute;
  top: 15px;
  right: 0;
  display: block;
  padding: 10px 25px 0 0;
}

.nav-toggle span,
.nav-toggle span:before,
.nav-toggle span:after {
  @include border-radius(10px);
  content: '';
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  cursor: pointer;
  transition: 0.2s all ease-out;
}

.nav-toggle--white span,
.nav-toggle--white span:before,
.nav-toggle--white span:after {
  background-color: $white;
}

.nav-toggle--dove-gray span,
.nav-toggle--dove-gray span:before,
.nav-toggle--dove-gray span:after {
  background-color: $dove-gray;
}

.nav-toggle span:before {
  top: -6px;
}

.nav-toggle span:after {
  bottom: -6px;
}

.nav-toggle.is-active span {
  background-color: transparent;
}

.nav-toggle.is-active span:before,
.nav-toggle.is-active span:after {
  top: 0;
}

.nav-toggle.is-active span:before {
  transform: rotate(45deg);
}

.nav-toggle.is-active span:after {
  transform: rotate(-45deg);
}
