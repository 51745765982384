.location {
  margin-bottom: 10px;

  &:not(:last-child) {
    margin-right: 30px;
  }
}
.locations--layout-1 {
  i {
    margin-right: 10px;
  }
}

.locations--layout-2 {
  i {
    @include font-size(16px);
    @include border-radius(50%);
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid $white;
    margin: 0 auto 10px;
    transition: border 0.3s ease-out;
  }

  a:hover i {
    border: 1px solid $color-secondary;
  }
}

.locations--layout-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;

  .location {
    @include border-radius(3px);
    flex-basis: 120px;
    margin-right: 0;
    margin-bottom: 0;
  }

  a {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  @include MQ(S) {
    max-width: 380px;

    .location {
      margin-bottom: 10px;
    }

    .location:not(:nth-child(3n)) {
      margin-right: 10px;
    }
  }

  img {
    height: 30px;
    margin: 0 auto 20px;
  }
}
