.settings__header {
  @include font-size(26px);
  margin-bottom: 40px;
  font-weight: 400;
}

.setting-group {
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid $alto;

  &:last-child {
    border-bottom: 0;
  }
}

.setting-group__title {
  @include font-size(14px);
  margin-bottom: 20px;
}

.setting {
  @include font-size(16px);
  position: relative;
  margin-bottom: 10px;
  padding-left: 25px;
}

.setting--current .setting__icon,
.setting--current .setting__link,
.setting:hover .setting__icon,
.setting:hover .setting__link {
  color: $color-secondary;
}

.setting__link {
  display: block;
}

.setting__icon {
  position: absolute;
  left: 0;
  transition: color 0.3s ease-out;
}
