.site-footer {
  color: $gallery;
}

.site-footer__primary {
  padding: 75px 0;
  background-color: $mine-shaft;
}

.site-footer__logo {
  margin-bottom: 10px;

  img {
    margin-right: auto;
    margin-left: auto;
  }
}

.site-footer__links a {
  color: $gallery;

  &:hover {
    color: $color-secondary;
  }
}

.site-footer__social {
  @include font-size(20px);
  li:not(:last-child) {
    margin-right: 20px;
  }
}

.site-footer__secondary {
  padding: 20px 0;
  background-color: $color-primary;
}

.site-footer__secondary-wrapper {
  @include MQ(M) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.site-footer__copyright {
  margin-bottom: 10px;

  @include MQ(M) {
    margin-bottom: 0;
  }
}

.site-footer__details {
  li:not(:last-child) {
    margin-right: 10px;
  }

  li:not(:last-child):after {
    content: '/';
    display: inline-block;
    margin-left: 10px;
  }
}
