.top-search-form {
  @include MQ(L) {
    min-width: 200px;
  }
}

.top-search-form__input {
  display: block;
  width: 100%;
  color: $white;

  &::placeholder {
    color: $white;
  }
}
