.comments-wrap:not(:last-child) {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid $alto;
}

.comments-title {
  @include font-size(24px);
  margin-bottom: 40px;
}

.comment:not(:last-child) {
  margin-bottom: 50px;
}

.comment-wrapper {
  display: flex;
}

.comment-avatar {
  flex-shrink: 0;
  display: none;
  max-width: 70px;
  border-radius: 50%;
  margin-right: 20px;

  @include MQ(M) {
    display: block;
  }
}

.comment-content {
  flex: 1;
}

.comment {
  .children {
    margin-top: 50px;
  }
}

.comment-author {
  @include font-size(18px);
  margin-bottom: 5px;
  @include MQ(S) {
    margin-bottom: 0;
  }
}

.comment-header {
  margin-bottom: 20px;

  @include MQ(S) {
    display: flex;
    justify-content: space-between;
  }
}

.comment-reply {
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
}
