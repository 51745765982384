.main-search {
  @include border-radius(3px);

  label {
    font-weight: 600;
  }
}

.main-search__group:not(:last-of-type) {
  margin-bottom: 15px;
}

.main-search--layout-1 {
  display: block;
  max-width: 730px;
  margin: 0 auto 20px;
  padding: 25px;

  @include MQ(L) {
    display: flex;
    align-items: flex-end;
  }

  .main-search__group--primary {
    @include MQ(L) {
      flex: 3;
    }
  }

  .main-search__group--secondary {
    @include MQ(L) {
      flex: 2;
    }
  }

  .main-search__group--tertiary {
    text-align: center;
  }

  .main-search__group:not(:last-of-type) {
    @include MQ(L) {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.main-search--layout-2 {
  padding: 30px;

  .main-search__group:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
