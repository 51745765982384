.post {
  position: relative;
  margin-top: 30px;
}

.post-image {
  margin-bottom: 40px;
}

.post-thumbnail {
  position: relative;
  overflow: hidden;

  img {
    transition: 0.5s transform ease-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.entry-header {
  margin-bottom: 45px;
}

.entry-title {
  @include font-size(30px);
  margin-bottom: 15px;
}

.entry-content {
  margin-bottom: 70px;
}

.entry-meta {
  margin-bottom: 40px;
  padding-bottom: 25px;
  border-bottom: 1px solid $alto;

  @include MQ(M) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
  }
}

.entry-meta > * {
  margin-bottom: 15px;

  @include MQ(M) {
    margin-bottom: 0;
  }
}

.entry-tags {
  a {
    @include border-radius(3px);
    display: inline-block;
    margin-bottom: 5px;
    padding: 5px 15px;
    border: 1px solid $alto;

    &:hover {
      border-color: $color-secondary;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.social-list {
  li {
    @include font-size(20px);

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  a {
    color: $dove-gray;

    &:hover {
      color: $color-secondary;
    }
  }
}

.post.sticky:before {
  @include font-size(10px);
  content: 'Sticky';
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline-block;
  background-color: $color-secondary;
  padding: 2px 10px;
  border-radius: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.post-title {
  @include font-size(20px);
  margin-bottom: 10px;
}

.post-meta {
  margin-bottom: 0;

  span:not(:last-child):after {
    content: '//';
    display: inline-block;
    padding: 0 5px;
    color: $dusty-gray;
  }
}

.news--layout-1,
.news--layout-3 {
  .post-thumbnail {
    margin-bottom: 20px;
  }
}

.news--layout-2,
.news--layout-4 {
  .post-content {
    padding: 20px;

    @include MQ(M) {
      padding: 25px 30px 30px;
    }
  }

  .post-meta {
    padding-top: 20px;
    border-top: 1px solid $alto;

    @include MQ(M) {
      padding-top: 25px;
    }
  }
}
