.page-banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.page-banner--layout-1 {
  padding: 120px 0 75px;
  background-image: url('../images/uploads/page-banner-1.jpg');

  @include MQ(L) {
    padding: 180px 0 135px;
  }
}

.page-banner__textcontent {
  margin-bottom: 20px;

  @include MQ(L) {
    margin-bottom: 40px;
  }
}

.page-banner__title {
  @include font-size(40px);
  margin-bottom: 10px;
}

.page-banner__subtitle {
  @include font-size(16px);
  margin-bottom: 0;
}

.page-banner--layout-2 {
  padding: 120px 0;
  background-image: url('../images/uploads/page-banner-2.jpg');

  @include MQ(L) {
    padding: 210px 0;
  }

  .page-banner__container {
    display: flex;
    flex-direction: column;

    @include MQ(L) {
      flex-direction: row-reverse;
      align-items: center;
    }
  }

  .page-banner__left,
  .page-banner__right {
    @include MQ(L) {
      flex: 1;
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  .page-banner__left {
    margin-bottom: 50px;

    @include MQ(L) {
      margin-bottom: 0;
    }
  }
}

.page-banner--layout-3 {
  padding: 100px 0;
  background-image: url('../images/uploads/page-banner-3.jpg');

  @include MQ(L) {
    padding: 185px 0;
  }
}

.page-banner--layout-4 {
  padding: 150px 0 140px;
  background-image: url('../images/uploads/page-banner-4.jpg');

  @include MQ(L) {
    padding: 300px 0 280px;
  }

  @include MQ(XL) {
    .main-search {
      max-width: 380px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .page-banner__wrapper {
    display: flex;
    flex-direction: column;

    @include MQ(L) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    @include MQ(XL) {
      max-width: 960px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .page-banner__left,
  .page-banner__right {
    @include MQ(L) {
      flex: 1;
    }
  }

  .page-banner__left {
    margin-bottom: 50px;

    @include MQ(L) {
      margin-bottom: 0;
    }

    @include MQ(XL) {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
}
