.create-listing__wrapper {
  padding: 20px;

  @include MQ(M) {
    padding: 50px;
  }

  .page-title {
    margin-bottom: 30px;
  }
}
