.page-content {
  padding: 20px 0 100px;

  &--no-b-bottom {
    padding-bottom: 0;
  }

  &--no-t-bottom {
    padding-top: 0;
  }
}

.page-title {
  @include font-size(30px);
  margin-bottom: 10px;
}
