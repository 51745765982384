.instruction-container {
  margin-top: 30px;
}
.instruction--layout-1 {
  .instruction-container {
    .instruction__step:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 15%;
      left: 50%;
      width: 80px;
      height: 20px;
      background-color: rgba(252, 196, 0, 0.5);
      border-radius: 15px;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .instruction-container:first-of-type {
    .instruction__step:before {
      background-color: rgba(252, 196, 0, 0.2);
    }
  }

  .instruction-container:last-of-type {
    .instruction__step:before {
      background-color: rgba(252, 196, 0, 0.7);
    }
  }
}

.instruction__step {
  @include font-size(48px);
  position: relative;
  display: inline-block;
  font-weight: 600;
  z-index: 2;
}

.instruction__title {
  @include font-size(20px);
}

.instruction__detail {
  max-width: 260px;
  margin: 0 auto;
}

.instruction__image {
  margin-bottom: 25px;

  img {
    height: 60px;
    margin-right: auto;
    margin-left: auto;
  }
}

.instruction--layout-2 {
  padding-top: 0;

  @include MQ(L) {
    .instructions {
      display: flex;
    }
    .instruction-container {
      flex: 1;
    }

    .instruction-container:nth-child(odd) {
      background-color: $mercury;
    }

    .instruction-container:nth-child(even) {
      background-color: $wild-sand;
    }

    .instruction {
      position: relative;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .instruction-container:not(:last-child) .instruction:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 100%;
      width: 0;
      height: 0;
      display: block;
      margin-top: -123px;
      border-top: 123px solid transparent;
      border-bottom: 123px solid transparent;
      border-left: 30px solid $mercury;
      z-index: 2;
    }

    .instruction-container:nth-child(2) .instruction:after {
      border-left-color: $wild-sand;
    }
  }

  @include MQ(XL) {
    .instruction-container:not(:last-child) .instruction:after {
      border-left-width: 45px;
    }
  }
}
