a {
  color: $color-primary;
  text-decoration: none;
  transition: 0.3s all ease-out;

  &:hover,
  &:focus,
  &:active {
    color: $color-secondary;
  }

  &:focus {
    outline: thin dotted;
  }

  &:hover,
  &:active {
    outline: none;
  }
}
