.dream-place {
  position: relative;
  margin-top: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: box-shadow 0.3s ease-out;

  &:hover {
    box-shadow: 0px 6px 19px 1px rgba(0, 0, 0, 0.4);
  }
}

.dream-places--layout-1 {
  .dream-place {
    height: 270px;
  }

  .dream-place__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease-out;

    &:before {
      content: '';
      position: absolute;
      top: 25px;
      right: 25px;
      bottom: 25px;
      left: 25px;
      border: 1px solid $white;
      opacity: 0;
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;
      transform: scale(1.1);
      z-index: 1;
    }
  }

  .dream-place:hover {
    .dream-place__inner {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .dream-place__inner:before {
      opacity: 1;
      transform: scale(1);
    }
  }

  @include MQ(S) {
    .dream-places__wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .dream-place {
      flex: 1 0 calc(50% - 30px);
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  @include MQ(M) {
    .dream-place {
      flex: 1 0 calc(33.33% - 30px);
    }
  }

  .dream-place__title {
    @include font-size(20px);
    margin: auto;

    @include MQ(M) {
      @include font-size(28px);
    }
  }
}

.dream-places--layout-2 {
  .dream-place {
    position: relative;
    height: 200px;
    overflow: hidden;

    @include MQ(M) {
      height: 250px;
    }

    @include MQ(L) {
      height: 400px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: $color-primary;
      opacity: 0.3;
      z-index: 1;
    }
  }

  .dream-place:hover {
    .dream-place__gradient {
      opacity: 0.5;
      transform: translateY(0);
    }
    .dream-place__number {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .dream-place__gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #e1006a 0%, #087377 100%);
    opacity: 0;
    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    transform: translateY(100%);
    z-index: 2;
  }

  .dream-place__content {
    position: relative;
    z-index: 10;
    padding: 15px;
  }

  .dream-place__title {
    @include font-size(18px);
    margin-bottom: 5px;

    @include MQ(M) {
      @include font-size(24px);
    }
  }

  .dream-place__number {
    display: block;
    font-weight: bold;
    opacity: 0;
    transition: 0.3s transform ease-out, 0.3s opacity ease-out;
    transform: translateY(50px);
  }
}
