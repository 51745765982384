ul,
ol {
  margin: 0 0 1.5rem;
  padding: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5rem;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5rem 1.5rem;
}

.min-list {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

.inline-list {
  li {
    display: inline-block;
  }
}
