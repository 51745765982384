.accordion-container {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    bottom: 3px;
    display: inline-block;
    width: 0;
    border-left: 1px dashed $silver-chalice;
  }
}

.accordion {
  position: relative;
  padding-left: 20px;
  margin-bottom: 35px;

  &:before {
    @include border-radius(50%);
    @include font-size(8px);
    content: '\f111';
    position: absolute;
    left: -8px;
    top: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: $color-secondary;
    color: $white;
    text-align: center;
    line-height: 16px;
    font-family: 'FontAwesome';
  }
}

.accordion:first-of-type {
  &:before {
    @include font-size(10px);
    content: '\f041';
    left: -10px;
    width: 20px;
    height: 20px;
    background-color: $purple;
    line-height: 20px;
  }
}

.accordion:last-of-type {
  &:before {
    content: '\f024';
    background-color: $flamingo;
  }
}

.accordion__header {
  cursor: pointer;

  > * {
    @include font-size(16px);
    margin-bottom: 0;
    color: $dove-gray;

    .accordion.is-open & {
      color: $color-primary;
    }
  }
}

.accordion__content {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;
}

.accordion__inner {
  padding-top: 25px;
}

.accordion__image {
  max-width: 200px;
  flex-shrink: 0;
  margin-right: 15px;
  margin-bottom: 15px;

  @include MQ(S) {
    margin-bottom: 0;
  }
}

.accordion__desc {
  flex: 1;
}
