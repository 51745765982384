.testimonial--layout-1,
.testimonial--layout-2 {
  .testimonials {
    @include MQ(M) {
      padding-left: 80px;
      background-image: url('../images/quote.png');
      background-repeat: no-repeat;
      background-position: left 35%;
    }
  }
}

.testimonial--layout-2 {
  .page-section__title {
    text-align: center;

    @include MQ(M) {
      text-align: left;
    }
  }
}

.testimonial--layout-3 {
  padding-top: 0;

  .testimonials {
    background-image: url('../images/quote.png');
    background-repeat: no-repeat;
    background-position: center 30%;
  }
}

.testimonial-content {
  @include font-size(16px);
  max-width: 650px;
  margin-bottom: 30px;
  padding-top: 15px;

  .testimonial--layout-3 & {
    margin-right: auto;
    margin-left: auto;
  }
}

.testimonial-footer {
  @include MQ(M) {
    display: flex;
    align-items: center;
  }

  .testimonial--layout-3 & {
    @include MQ(M) {
      justify-content: center;
      flex-direction: column;
    }
  }
}

.testimonial-avatar {
  flex-shrink: 0;
  max-width: 80px;
  margin-right: 15px;
  margin-bottom: 10px;
  border-radius: 50%;

  @include MQ(M) {
    margin-bottom: 0;
  }
}

.testimonial-client-name {
  @include font-size(16px);

  &:after {
    content: '//';
    display: inline-block;
    padding: 0 5px;
  }
}

.testimonial-container {
  position: relative;
}

.testimonial-button-container {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;

  .testimonial--layout-3 & {
    @include MQ(M) {
      right: 0;
      bottom: 50%;
      left: 0;
      display: flex;
      justify-content: space-between;
      transform: translateY(-50%);
    }
  }
}

.testimonial-button {
  display: inline-block;
  width: 35px;
  height: 35px;
  border: 1px solid $silver-chalice;
  color: $silver-chalice;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.testimonial-button.swiper-button-disabled {
  border-color: $gallery;
  color: $gallery;
}

.testimonial-video {
  position: relative;
  margin-bottom: 20px;
  border-radius: 50%;

  @include MQ(M) {
    margin-bottom: 0;
  }

  a {
    @include font-size(50px);
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    color: $white;
    transition-property: transform, color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;

    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
      color: $color-secondary;
    }
  }

  img {
    margin-right: auto;
    margin-left: auto;
  }
}
