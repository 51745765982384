.rangeslider {
  @include border-radius(5px);
  position: relative;
  height: 5px;
  width: 100%;
  margin-bottom: 30px;
  background-color: $alto;
}

.rangeslider__handle {
  position: absolute;
  top: -8px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: $color-secondary;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  touch-action: pan-y;
  cursor: pointer;
  z-index: 3;
  transition: background-color 0.2s;
}

.rangeslider__fill {
  @include border-radius(5px);
  position: absolute;
  top: 0;
  height: 100%;
  background-color: $color-secondary;
  z-index: 1;
}

.rangeslider__rulers {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
}

.rangeslider__ruler {
  position: relative;
  padding-top: 15px;
  cursor: pointer;
  transform: translateX(50%);

  &:first-child {
    transform: translateX(-20%);
  }

  &:last-child {
    transform: translateX(20%);
  }
}

.rangeslider__handle-value {
  @include border-radius(3px);
  position: relative;
  bottom: 30px;
  right: 2px;
  width: 25px;
  height: 20px;
  background-color: $color-secondary;
  color: $white;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 5px solid $color-secondary;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.05);
    transform: translateX(-50%);
  }
}
