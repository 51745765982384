.page-numbers {
  @include border-radius(3px);
  display: inline-block;
  border: 1px solid transparent;
  text-align: center;

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.page-navigation-container {
  padding-top: 50px;

  .page-numbers {
    height: 40px;
    padding-right: 17px;
    padding-left: 17px;
    border: 1px solid $mercury;
    line-height: 40px;

    &:hover {
      border: 1px solid $color-secondary;
      background-color: $color-secondary;
      color: $color-primary;
    }
  }

  .page-numbers.current {
    border: 1px solid $color-secondary;
    background-color: $color-secondary;
  }
}

.listing-pagination {
  .page-numbers {
    @include font-size(16px);
    height: 36px;
    padding-right: 15px;
    padding-left: 15px;
    background-color: $wild-sand;
    border-color: $gallery;
    line-height: 36px;

    &:hover {
      border-color: $tundora;
      background-color: $tundora;
      color: $white;
    }
  }

  .page-numbers.current {
    border-color: $tundora;
    background-color: $tundora;
    color: $white;
  }
}

.nav-links {
  padding-bottom: 25px;
  margin-bottom: 40px;
  border-bottom: 1px solid $alto;

  @include MQ(M) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
  }
}

.nav-links > * {
  margin-bottom: 15px;

  @include MQ(M) {
    flex: 1;
    margin-bottom: 0;
  }
}

.nav-link {
  position: relative;

  @include MQ(M) {
    &:before {
      @include font-size(25px);
      position: absolute;
      top: 50%;
      diplay: inline-block;
      font-family: 'Ionicons';
      transform: translateY(-50%);
    }
  }
}

.nav-previous {
  @include MQ(M) {
    padding-right: 25px;

    &:before {
      content: '\f3d2';
      left: -40px;
    }
  }
}

.nav-next {
  @include MQ(M) {
    padding-left: 25px;
    text-align: right;

    &:before {
      content: '\f3d3';
      right: -40px;
    }
  }
}

.nav-link-title {
  @include font-size(20px);
  margin-bottom: 0;
}

.nav-link-text {
  @include MQ(M) {
    display: none;
  }
}
