.contact-container {
  .page-section__title {
    margin-bottom: 15px;
  }
}

.contact-map-container {
  position: relative;
  min-height: 400px;
  margin-bottom: 30px;

  @include MQ(M) {
    margin-bottom: 0;
  }
}

.contact {
  margin-top: 40px;
}

.contact-list {
  margin-bottom: 75px;
}

.contact-icon {
  @include font-size(24px);
  width: 70px;
  height: 70px;
  margin: 0 auto 30px;
  color: $white;
  border-radius: 50%;
  line-height: 70px;
  text-align: center;
}

.contact-type {
  @include font-size(16px);
  margin-bottom: 10px;

  ~ a {
    display: block;
  }
}
