img {
  display: block;
  max-width: 100%;
}

figure {
  margin: 0;
}

hr {
  height: 1px;
  margin-bottom: 1.5rem;
  border: 0;
  background-color: $dove-gray;
}
