.stars-outer {
  @include font-size(16px);
  position: relative;
  display: inline-block;
  color: $dusty-gray;
  font-family: 'FontAwesome';
}

.stars-outer:before {
  content: '\f005 \f005 \f005 \f005 \f005';
  color: $alto;
}

.stars-inner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
  width: 0;
}

.stars-inner:before {
  content: '\f005 \f005 \f005 \f005 \f005';
  color: $color-secondary;
}

.stars {
  @include font-size(20px);
  color: $alto;
  cursor: pointer;
}

.star.hover {
  color: $color-secondary;
}

.star.is-selected {
  color: $color-secondary;
}
