.coming-soon__header {
  padding: 65px 0;

  img {
    margin-right: auto;
    margin-left: auto;
  }
}

.coming-soon__main {
  padding-top: 80px;
  padding-bottom: 80px;

  @include MQ(M) {
    padding-top: 125px;
    padding-bottom: 125px;
  }
}

.coming-soon__title {
  @include font-size(30px);
  margin-bottom: 30px;

  @include MQ(M) {
    margin-bottom: 60px;
  }
}

.coming-soon__wrapper:not(:last-child) {
  margin-right: 5px;

  @include MQ(M) {
    margin-right: 20px;
  }

  @include MQ(L) {
    margin-right: 35px;
  }
}

.coming-soon__time {
  @include font-size(20px);
  @include border-radius(3px);
  display: block;
  margin-bottom: 15px;
  width: 60px;
  height: 70px;
  border-bottom: 3px solid $gray;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  line-height: 70px;

  @include MQ(M) {
    @include font-size(48px);
    width: 140px;
    height: 150px;
    line-height: 150px;
  }
}

.coming-soon__unit {
  @include font-size(12px);

  @include MQ(M) {
    @include font-size(16px);
  }
}

.coming-soon__footer {
  padding-top: 70px;
  padding-bottom: 70px;
}

.coming-soon__form-container {
  max-width: 540px;
  margin-right: auto;
  margin-left: auto;
}

.coming-soon__form {
  display: flex;
  margin-bottom: 30px;

  > input {
    width: 70%;
  }

  > button {
    width: 30%;
    padding: 12px;
  }
}
