.user-action {
  position: absolute;
  top: 18px;
  right: 40px;
  @include MQ(L) {
    position: static;
    padding-left: 10px;

    & > * {
      margin-left: 15px;
    }
  }
  @include MQ(XL) {
    padding-left: 20px;

    & > * {
      margin-left: 30px;
    }
  }
}

.sign-in {
  display: inline-block;
}
