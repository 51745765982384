.map-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  display: inline-block;
  z-index: 10;
}

.map-control:not(:last-child) {
  margin-bottom: 5px;
}

.map-control-button {
  width: 40px;
  height: 40px;
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0 1px 2.97px 0.03px rgba(0, 0, 0, 0.2);
  transition: background-color .3s ease-out;

  &:hover {
    background-color: $color-secondary;
  }
}
