.listing {
  @include border-radius(3px);
  position: relative;
  margin-top: 30px;

  p + p {
    margin-bottom: 0;
  }
}

.listing__thumbnail {
  position: relative;
  overflow: hidden;

  img {
    transition: transform 0.5s ease-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.listing__detail {
  padding: 20px;
  border: 1px solid $gallery;
}

.listing__title {
  @include font-size(20px);
  margin-bottom: 10px;
}

.listing__category {
  margin-bottom: 10px;

  span:not(:last-child):after {
    @include font-size(20px);
    content: '\00B7';
    display: inline-block;
    padding: 0 5px;
    color: $alto;
  }
}

.listing__price {
  @include font-size(10px);
}

.listing__score {
  display: inline-block;
  width: 34px;
  height: 34px;
  border: 1px solid $flamingo;
  color: $flamingo;
  border-radius: 50%;
  line-height: 34px;
}

.listing-list--layout-3 {
  .listing__detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .listing__location {
    margin-bottom: 0;
  }
}

.listing-row:first-child {
  th {
    padding: 20px 0.4rem;
  }
  th:first-child {
    padding-left: 15px;
  }
}

.listing-cell {
  padding: 30px 0;

  .listing__wrapper {
    display: flex;
    align-items: center;
  }

  .listing__detail {
    padding: 0;
    border: 0;
  }

  .listing__thumbnail {
    display: none;
    width: 150px;
    margin-right: 20px;

    @include MQ(M) {
      display: block;
    }
  }

  p {
    margin-bottom: 15px;
  }
}

.listing-group {
  margin-bottom: 35px;
  padding-bottom: 25px;
  border-bottom: 1px solid $gallery;

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

.listing-group__title {
  @include font-size(20px);
}

.listing-header {
  .listing-action i {
    @include font-size(18px);
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    background-color: $gallery;
    line-height: 50px;
    border-radius: 50%;
  }

  .listing-action--favorite i {
    color: $flamingo;
  }

  .listing-action--report i {
    color: $color-secondary;
  }

  .listing-action--share i {
    color: $picton-blue;
  }

  .listing-action:not(:last-child) {
    margin-right: 20px;
  }

  .listing-actions--secondary {
    margin-top: 30px;

    li {
      margin-bottom: 10px;
    }

    li:not(:last-child) {
      margin-right: 10px;
    }
  }

  @include MQ(M) {
    .listing-actions--secondary li {
      margin-bottom: 0;
    }
  }
}

.listing-header__container {
  padding-bottom: 30px;

  .listing-section-container & {
    padding-bottom: 0;
  }
}

.listing-header__main {
  margin-bottom: 15px;

  @include MQ(L) {
    margin-bottom: 0;
  }
}

.listing-header__image {
  margin-right: 15px;
}

.listing-header__content {
  margin-bottom: 15px;
  @include MQ(M) {
    margin-bottom: 0;
  }
}

.listing-header__title {
  @include font-size(20px);

  margin-bottom: 5px;

  @include MQ(S) {
    @include font-size(30px);
    margin-right: 5px;
    margin-bottom: 0;
  }
}

.listing-header__detail {
  margin-top: 5px;

  li {
    margin-bottom: 5px;
  }

  @include MQ(M) {
    li {
      display: inline-block;
    }

    li:not(:last-child):after {
      content: '|';
      display: inline-block;
      color: $alto;
      padding: 0 10px;
    }
  }

  .listing__score {
    @include font-size(12px);
    width: 26px;
    height: 26px;
    border-color: $color-secondary;
    background-color: $color-secondary;
    color: $color-primary;
    line-height: 26px;
  }
}

.listing-widget {
  margin-bottom: 30px;
  padding: 25px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .listing-actions {
    margin-top: 20px;
  }

  .listing-action:not(:last-child):after {
    content: '|';
    display: inline-block;
    color: $alto;
    padding: 0 10px;
  }
}

.listing-widget__title {
  @include font-size(20px);
  padding-bottom: 15px;
  border-bottom: 1px solid $gallery;
}

.latest-listing:not(:last-child) {
  margin-bottom: 35px;
}

.latest-listing__thumbnail {
  flex-shrink: 0;
  max-width: 60px;
  margin-right: 15px;
}

.latest-listing__name {
  @include font-size(16px);
  margin-bottom: 2px;
}

.latest-listing__desc {
  margin-bottom: 0;
}

.listing-timetable {
  th {
    width: 25%;
  }
  tr,
  th {
    padding: 10px;
  }
}

.listing-main {
  padding: 40px 0 100px;
}

.listing-map-container {
  position: relative;
  min-height: 180px;
  margin-bottom: 25px;
}

.listing-contact {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid $gallery;
  padding-left: 25px;
}

.listing-contact__icon {
  position: absolute;
  left: 0;
}

.listing-social {
  .social-list {
    margin-top: 10px;
    margin-bottom: 35px;
  }
}

.listing-cta {
  margin-top: 20px;
  margin-bottom: 0;
}

.listing-tab {
  &:not(:last-child) {
    margin-right: 15px;

    @include MQ(M) {
      margin-right: 30px;
    }
  }
  a {
    @include font-size(16px);
    display: block;
    border-bottom: 2px solid transparent;
    font-weight: 600;
    padding: 15px 0;

    &:hover {
      border-bottom: 2px solid $color-secondary;
    }
  }
}

.listing-tab.is-active {
  a {
    border-bottom: 2px solid $color-secondary;
    color: $color-secondary;
  }
}

.listing-section,
.listing-header-wrapper {
  padding: 20px;
  margin-bottom: 30px;

  @include MQ(M) {
    padding: 40px;
  }
}

.listing-section:last-child {
  margin-bottom: 0;
}

.listing-section__header {
  margin-bottom: 30px;
}

.listing-section__title {
  @include font-size(24px);
  margin-bottom: 10px;

  @include MQ(S) {
    margin-bottom: 0;
  }
}

.listing-features {
  margin-bottom: 15px;

  @include MQ(S) {
    column-count: 3;
  }

  li {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;

    &:before {
      content: '\f00c';
      position: absolute;
      left: 0;
      display: inline-block;
      color: $color-secondary;
      font-family: 'FontAwesome';
    }
  }
}

.listing-ratings {
  margin-bottom: 40px;
}

.listing-rating {
  border-bottom: 0;

  th,
  td {
    padding: 10px 0;
  }

  td:nth-child(3) {
    display: none;

    @include MQ(M) {
      display: table-cell;
      width: 50%;
    }
  }

  td:last-child {
    text-align: right;
  }
}

.listing-review {
  padding-top: 35px;
  padding-bottom: 20px;
  border-top: 1px dotted $alto;
}

.listing-review__avatar {
  flex-shrink: 0;
  max-width: 60px;
  margin-right: 15px;
  border-radius: 50%;
}

.listing-review__content {
  flex: 1;
}

.listing-review__header {
  margin-bottom: 10px;
}

.listing-pagination {
  margin-top: 15px;

  .page-navigation {
    margin-bottom: 10px;

    @include MQ(S) {
      margin-bottom: 0;
    }
  }
}

.listing-menu {
  @include MQ(M) {
    column-count: 2;
    column-gap: 30px;
  }
}

.listing-menu__item {
  margin-bottom: 20px;
  padding-bottom: 25px;
  border-bottom: 1px dotted $silver-chalice;
}

.listing-menu__item-name {
  @include font-size(18px);
  margin-bottom: 5px;
}

.listing-gallery-top {
  width: 100%;
  margin-bottom: 10px;
}

.listing-gallery-thumb {
  width: 100%;
}

.listing-gallery-thumb .swiper-slide {
  opacity: 0.4;
  cursor: pointer;
}

.listing-gallery-thumb .is-selected {
  opacity: 1;
}

.listing-button {
  @include font-size(16px);
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  transform: translateY(-50%);
  z-index: 1000;

  @include MQ(M) {
    @include font-size(20px);
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  &:hover {
    background-color: $color-secondary;
  }

  &.swiper-button-disabled {
    opacity: 0;
    visibility: hidden;
  }
}

.listing-button-prev {
  left: 10px;
}

.listing-button-next {
  right: 10px;
}

.listing-member__image {
  max-width: 120px;
  flex-shrink: 0;
  margin-right: 20px;
  border-radius: 50%;
}

.listing-member__name {
  @include font-size(20px);
  margin-bottom: 2px;
}

.listing-member__position {
  margin-bottom: 15px;
}

.listing-timer__desc {
  margin-bottom: 10px;
}

.listing-timer__wrapper:not(:last-child) {
  margin-right: 10px;
}

.listing-timer__time {
  @include font-size(25px);
  font-weight: 700;
}

.listing-nav--b-margin {
  margin-bottom: 30px;
}

.listing-header-bg {
  height: 280px;
  background-image: url('../images/uploads/listing-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.listing-search-container {
  @include border-radius(3px);

  @include MQ(L) {
    margin-top: -110px;
  }
}

.listing-search-container--on-map {
  @include MQ(L) {
    margin-top: -70px;
  }
}

.listing-search {
  padding: 15px 20px;
  box-shadow: 0 1px 2.97px 0.03px rgba(0, 0, 0, 0.15);

  button {
    padding-right: 20px;
    padding-left: 20px;
    font-weight: 400;
  }
}

.listing-sort {
  li:not(:last-child) {
    margin-right: 10px;
  }

  a {
    color: $dusty-gray;

    &:hover {
      color: $color-secondary;
    }
  }

  a.is-active {
    color: $color-primary;
  }
}

.listing-filter {
  margin-right: 15px;
  margin-bottom: 15px;

  @include MQ(L) {
    margin-bottom: 0;
  }
  a {
    display: block;
    padding: 10px 20px;
    color: $color-primary;
    outline: none;
  }
  li {
    border: 1px solid $gallery;
    background-color: $wild-sand;
  }
  li.is-active {
    background-color: $color-secondary;
    border-color: transparent;
  }
  i {
    pointer-events: none;
  }
}

.listing-filter--group {
  li:not(:first-child) {
    border-left: 0;
  }

  li:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  li:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

.listing-filter--list {
  li {
    margin-bottom: 5px;
    border-radius: 20px;

    @include MQ(S) {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.listing-keyword {
  @include font-size(20px);
  padding-top: 25px;
  padding-bottom: 25px;

  .keyword {
    @include font-size(36px);
  }

  @include MQ(L) {
    padding-top: 0;
    color: $white;
  }
}

.keyword {
  @include font-size(16px);
}

.listing-keyword--on-map {
  background-color: $white;

  @include MQ(L) {
    padding: 10px 20px;
    border: 1px solid $gallery;
    color: $color-primary;
  }
}

.listing-expand {
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 15px;

  @include MQ(L) {
    margin-bottom: 0;
  }

  i {
    transition: transform 0.3s ease-out;
  }

  &.is-active i {
    transform: rotate(180deg);
  }
}

.listing-more-filter {
  height: 0;
  margin-bottom: 30px;
  overflow: hidden;
  transition: height 0.3s ease-out;

  ul {
    @include MQ(S) {
      column-count: 2;
    }

    @include MQ(L) {
      column-count: 4;
    }
  }
}

.listing-more-filter__inner {
  padding: 15px;

  @include MQ(M) {
    padding: 30px 50px 15px;
  }
}

.listing-header-map-container {
  position: relative;
  min-height: 430px;
}

.js-map-container.is-fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.listing-box {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 15px solid $white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}

.listing-box__image {
  flex-shrink: 0;
  max-width: 65px;
  margin-right: 15px;
}

.listing-box__content {
  flex: 1 0 150px;
}

.listing-box__title {
  @include font-size(18px);
  margin-bottom: 5px;
}

.listing-box__address {
  display: inline-block;
  max-width: 190px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.listing-column {
  position: relative;
}

.listing-column--xl-50 {
  @include MQ(XL) {
    width: 50%;
  }
}

.listing-column--content {
  padding: 30px;
}

.listing-column--map {
  min-height: 500px;

  @include MQ(XL) {
    position: sticky;
    top: 0;
    height: 100vh;
  }
}

.grid-view__item {
  @include MQ(M) {
    width: 48%;
  }
}

.list-view {
  margin-top: 30px;
}

.list-view__item {
  .listing {
    @include MQ(S) {
      margin-top: 0;
      padding: 15px;
      border-bottom: 1px solid $gallery;
      transition: background-color 0.3s ease-out;

      &:hover {
        background-color: $wild-sand;
      }
    }

    @include MQ(M) {
      padding: 30px;
    }
  }

  .listing img {
    @include MQ(S) {
      width: 100%;
      height: 180px;
      object-fit: cover;
    }
  }

  .listing__thumbnail {
    @include MQ(S) {
      position: relative;
      width: 40%;
      margin-right: 15px;
    }
  }

  .listing__detail {
    @include MQ(S) {
      width: 60%;
      border: 0;
      padding: 0;
    }
  }
}

.listing-column {
  .listing-filter-container {
    margin-top: 20px;
  }

  .listing-filter--group {
    margin-bottom: 15px;
  }

  .listing-filter--list > * {
    margin-bottom: 15px;
  }
}

.listing-filter-label {
  display: block;
  margin-bottom: 15px;
}

.listing-filter--category {
  margin-right: 0;

  li {
    @include border-radius(3px);
    position: relative;
    flex: 0 0 120px;
    height: 50px;
    margin-bottom: 5px;
    background: transparent;

    @include MQ(L) {
      flex: 0 0 170px;
    }
  }

  li:not(:last-child) {
    margin-right: 10px;
  }

  li.is-active {
    .listing-filter__content {
      background-color: rgba(252, 196, 0, 0.5);
    }
  }

  a {
    padding: 0;
  }
}

.listing-filter__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.listing-filter__bg--coffee {
  background-image: url('../images/uploads/coffee.jpg');
}

.listing-filter__bg--food {
  background-image: url('../images/uploads/food.jpg');
}

.listing-filter__bg--cake {
  background-image: url('../images/uploads/cake.jpg');
}

.listing-filter__bg--fruit {
  background-image: url('../images/uploads/fruit.jpg');
}

.listing-filter__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
