.site-header {
  @include MQ(L) {
    box-shadow: 0px 3px 7.92px 0.08px rgba(0, 0, 0, 0.1);
  }
}

.site-header--absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.site-header--fluid {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 15px;

  @include MQ(L) {
    padding: 0 30px;
  }
}

.site-header__container {
  position: relative;
  padding: 10px 0;

  @include MQ(L) {
    height: 80px;
    padding: 0;
  }
}

.site-header__logo {
  display: inline-block;

  @include MQ(L) {
    margin-right: 50px;
  }

  @include MQ(XL) {
    margin-right: 100px;
  }
}
