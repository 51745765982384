.corner-ribbon {
  position: absolute;
  top: 15px;
  right: -30px;
  left: auto;
  display: block;
  width: 120px;
  line-height: 30px;
  background-color: $color-secondary;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  transform: rotate(45deg);
}
