.progressbar-container {
  @include border-radius(20px);
  display: block;
  width: 100%;
  height: 8px;
  border: 1px solid $gallery;
  background-color: $white;
}

.progressbar {
  @include border-radius(20px);
  display: block;
  background-color: $color-secondary;
  height: 100%;
}
